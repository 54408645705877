import React from 'react';

export default function VideoLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M43.86 0h8.28c11.661 0 18.564 1.74 24.675 5.01 6.112 3.267 10.908 8.063 14.175 14.175C94.26 25.295 96 32.197 96 43.86v8.28c0 11.661-1.74 18.564-5.01 24.675-3.267 6.112-8.063 10.908-14.175 14.175C70.704 94.26 63.801 96 52.14 96h-8.28c-11.661 0-18.564-1.74-24.675-5.01-6.112-3.267-10.908-8.063-14.175-14.175C1.74 70.705 0 63.803 0 52.14v-8.28c0-11.661 1.74-18.564 5.01-24.675C8.277 13.073 13.073 8.277 19.185 5.01 25.295 1.74 32.197 0 43.86 0z"
        />
        <path
          fill="#585aa8"
          d="M53.25 63h-25.5C25.679 63 24 61.321 24 59.25v-22.5c0-2.071 1.679-3.75 3.75-3.75h25.5c2.071 0 3.75 1.679 3.75 3.75v5.82l9.57-4.785c1.164-.583 2.546-.521 3.653.164 1.107.685 1.78 1.894 1.777 3.196V54.87c.008 1.3-.66 2.512-1.766 3.198-1.105.686-2.487.747-3.65.162L57 53.49v5.76C57 61.321 55.32 63 53.25 63zm-25.5-27c-.414 0-.75.336-.75.75v22.5c0 .414.336.75.75.75h25.5c.414 0 .75-.336.75-.75v-8.175c-.001-.518.266-1 .705-1.275.44-.275.99-.303 1.455-.075L67.92 55.5c.224.123.496.123.72 0 .22-.141.356-.383.36-.645v-13.71c-.004-.262-.14-.504-.36-.645-.224-.123-.496-.123-.72 0l-11.745 5.865c-.47.236-1.028.21-1.473-.069-.445-.28-.711-.77-.702-1.296v-8.25c0-.414-.336-.75-.75-.75h-25.5z"
        />
      </g>
    </svg>
  );
}
